<template>
  <!-- DATA - RIGHT SCROLLBAR -->
  <!-- :max-height="$vuetify.breakpoint.smAndDown ? 110 : 630" -->

  <v-card elevation="0" class="overflow-y-auto" color="#1a1b1d" style="margin-left: 5px"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`" :style="$vuetify.breakpoint.smAndDown
        ? `border-left: `
        : `border-left:1px solid gray`
      ">
    <v-card-text>
      <div>
        <div class="headText">
          <h2 class="HeadTextStyle" :style="fontSize">Projects</h2>
        </div>
        <v-container grid-list-md>
          <v-layout row justify-space-around wrap>
            <!-- 1. PROJEKAT -->
            <v-flex xs12 md6 lg5>
              <v-hover class="mt-7" v-slot:default="{ hover }" style="min-height: 590px; height: auto">
                <v-card @click="$router.push('/tacna')" class="mx-auto;" style="cursor: pointer"
                  color="grey lighten-3"><v-img :aspect-ratio="16 / 9" src="../assets/projects/Tacna/tacna-mock-up.jpg">
                    <v-expand-transition>
                      <div v-if="hover"
                        class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal headline white--text text-center"
                        style="height: 100%">
                        In progress
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pt-3" style="position: relative">
                    <div class="standard-text black--text subheading mb-2" style="height: 130px">
                      A web auction app for humanitarian fundraising in
                      partnership with "Solidarity Online Plus".
                      <!-- A web app for a humanitarian auction in partnership with "Solidarity Online Plus", 
                      aimed at raising funds for humanitarian causes. -->
                    </div>
                    <h3 class="headlineCust orange--text mt-2">Tacna</h3>
                    <div class="black--text captionCust mb-2">
                      (responsive design)
                    </div>
                    <v-row style="height: 160px">
                      <v-col class="standard-text">
                        <span class="black-Text"> Language: </span>
                        JavaScript<br />
                        <span class="black-Text"> Framework: </span>
                        Vue 3<br />
                        <span class="black-Text">UI Library:</span> Vuetify
                        <br />
                        <span class="black-Text">Database:</span> SQL <br />
                        <!-- <i class="black-Text">Database:</i> NoSQL <br /> -->
                        <span class="black-Text">Server:</span>
                        Firebase (FB and Google login API), Mars-server (data
                        storage & manipulation using JS as backend language)
                        <br /><br />
                      </v-col>
                    </v-row>
                    <v-row justify="end" class="mb-0">
                      <v-col justify="end">
                        <div class="black--text captionCust pt-2 text-right">
                          More info...
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-flex>
            <!-- 2. PROJEKAT -->
            <v-flex xs12 md6 lg5>
              <v-hover class="mt-7" v-slot:default="{ hover }" style="min-height: 590px; height: auto">
                <v-card @click="$router.push('/tetsu')" class="mx-auto;" style="cursor: pointer"
                  color="grey lighten-3"><v-img :aspect-ratio="16 / 9" src="../assets/projects/Tetsu/slika-13.jpg">
                    <v-expand-transition>
                      <div v-if="hover"
                        class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal headline white--text text-center"
                        style="height: 100%">
                        Click for more info...
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pt-3" style="position: relative">
                    <div class="standard-text black--text subheading mb-2" style="height: 130px">
                      Website developed for a carpet store in collaboration with
                      a graphic designer, utilizing client-provided images.
                    </div>
                    <h3 class="headlineCust orange--text mt-2">
                      Website Tetsu
                    </h3>
                    <div class="black--text captionCust mb-2">
                      (responsive design)
                    </div>
                    <v-row style="height: 160px">
                      <v-col class="standard-text">
                        <span class="black-Text"> Language: </span>
                        JavaScript <br />
                        <span class="black-Text"> Style: </span>
                        CSS<br />
                        <br />
                        <!-- <i class="black-Text"> Framework: </i>
                        Vue 3<br /> -->
                        <!-- <i class="black-Text">UI Library:</i> Vuetify
                        <br /> -->
                        <!-- <i class="black-Text">Database:</i> SQL <br /> -->
                        <!-- <i class="black-Text">Database:</i> NoSQL <br /> -->
                        <!-- <i class="black-Text">Server:</i>
                        Firebase (FB and Google login API), Mars-server (data
                        storage and manipulation using JS as backend language)
                        <br /><br /> -->
                      </v-col>
                    </v-row>
                    <v-row justify="end" class="mb-0">
                      <v-col justify="end">
                        <div class="black--text captionCust pt-2 text-right">
                          More info...
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-flex>
            <!-- 3. PROJEKAT -->
            <v-flex xs12 md6 lg5>
              <v-hover class="mt-7" v-slot:default="{ hover }" style="min-height: 590px; height: auto">
                <v-card @click="$router.push('/superlab')" class="mx-auto;" style="cursor: pointer"
                  color="grey lighten-3"><v-img :aspect-ratio="16 / 9" src="../assets/projects/SuperLab/slika-1.png">
                    <v-expand-transition>
                      <div v-if="hover"
                        class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal headline white--text text-center"
                        style="height: 100%">
                        Click for more info...
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pt-3" style="position: relative">
                    <div class="standard-text black--text" style="height: 130px">
                      Created a user-friendly Single-Page application (SPA) for
                      laboratory services with login, online result access,
                      automated cost calculation, and price updating.
                    </div>
                    <h3 class="headlineCust orange--text mt-2">SuperLab</h3>
                    <div class="black--text captionCust mb-2">
                      (responsive design)
                    </div>
                    <v-row style="height: 160px">
                      <v-col class="standard-text">
                        <span class="black-Text"> Language: </span>
                        JavaScript<br />
                        <span class="black-Text"> Framework: </span>
                        Vue 2<br />
                        <span class="black-Text">UI Library:</span> Vuetify
                        <br />
                        <span class="black-Text">Database:</span> NoSQL <br />
                        <span class="black-Text">Server:</span>
                        Firebase (hosting, authentication, cloud firestore,
                        storage)
                        <!-- <br /><br /> -->
                      </v-col>
                    </v-row>
                    <v-row justify="end" class="mb-0">
                      <v-col justify="end">
                        <div class="black--text captionCust pt-2 text-right">
                          More info...
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-flex>
            <!-- 4. PROJEKAT -->
            <v-flex xs12 md6 lg5>
              <v-hover class="mt-7" v-slot:default="{ hover }" style="min-height: 590px; height: auto">
                <v-card @click="$router.push('/holiday-planner')" class="mx-auto;" style="cursor: pointer"
                  color="grey lighten-3"><v-img :aspect-ratio="16 / 9"
                    src="../assets/projects/Holiday-planner/holidey-mock-up.jpg">
                    <v-expand-transition>
                      <div v-if="hover"
                        class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal headline white--text text-center"
                        style="height: 100%">
                        Click for more info...
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pt-3" style="position: relative">
                    <div class="standard-text black--text" style="height: 130px">
                      Real-Time Single-Page application (SPA) for multiple users
                      who use one plan for a more accessible organization when
                      preparing items for travel.
                    </div>
                    <h3 class="headlineCust orange--text mt-2">
                      Holiday planner
                    </h3>
                    <div class="black--text captionCust mb-2">
                      (responsive design)
                    </div>
                    <v-row style="height: 160px">
                      <v-col class="standard-text">
                        <span class="black-Text"> Language: </span>
                        JavaScript<br />
                        <span class="black-Text"> Framework: </span>
                        Vue 2<br />
                        <span class="black-Text">Database:</span> NoSQL <br />
                        <span class="black-Text">Server:</span>
                        Firebase (hosting, authentication, cloud firestore)
                        <!-- <br /><br /> -->
                      </v-col>
                    </v-row>
                    <v-row justify="end" class="mb-0">
                      <v-col justify="end">
                        <div class="black--text captionCust pt-2 text-right">
                          More info...
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-flex>

            <!-- 5. PROJEKAT -->
            <!-- <v-flex xs12 md6 lg5>
              <v-hover
                class="mt-7"
                v-slot:default="{ hover }"
                style="min-height: 590px; height: auto"
              >
                <v-card
                  @click="$router.push('/calculator')"
                  class="mx-auto;"
                  style="cursor: pointer"
                  color="grey lighten-3"
                  ><v-img
                    :aspect-ratio="16 / 9"
                    src="../assets/projects/Calculator/slika-1.png"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal headline white--text text-center"
                        style="height: 100%"
                      >
                        Click for more info...
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pt-3" style="position: relative">
                    <div
                      class="standard-text black--text subheading mb-2"
                      style="height: 130px"
                    >
                      Simple calculator working in Vue 3 with Composition API.
                    </div>
                    <h3 class="headlineCust orange--text mt-2">Calculator</h3>
                    <div class="black--text captionCust mb-2">
                      (responsive design)
                    </div>
                    <v-row style="height: 160px">
                      <v-col class="standard-text">
                        <span class="black-Text"> Language: </span>
                        JavaScript<br />
                        <span class="black-Text"> Framework: </span>
                        Vue 3<br />
                        <span class="black-Text">Server:</span>
                        Firebase (hosting)
                        <br /><br />
                      </v-col>
                    </v-row>
                    <v-row justify="end" class="mb-0">
                      <v-col justify="end">
                        <div class="black--text captionCust pt-2 text-right">
                          More info...
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-flex> -->

            <!-- 6. PROJEKAT -->
            <v-flex xs12 md6 lg5>
              <v-hover class="mt-7" v-slot:default="{ hover }" style="min-height: 590px; height: auto">
                <v-card @click="$router.push('/cookBook')" class="mx-auto;" style="cursor: pointer"
                  color="grey lighten-3"><v-img :aspect-ratio="16 / 9" src="../assets/projects/CookBook/enon.jpg">
                    <v-expand-transition>
                      <div v-if="hover"
                        class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal headline white--text text-center"
                        style="height: 100%">
                        Click for more info...
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pt-3" style="position: relative">
                    <!-- <v-row>
                      <v-col> -->
                    <div class="standard-text black--text subheading mb-2" style="height: 130px">
                      Interactive cooking recipe SPA with user login, favorites,
                      search and ingredient addition. Provides a seamless
                      experience for users to access, organize, and explore
                      recipes.
                    </div>
                    <h3 class="headlineCust orange--text mt-2">CookBook</h3>
                    <div class="black--text captionCust mb-2">
                      (responsive design)
                    </div>
                    <!-- </v-col>
                    </v-row> -->
                    <v-row style="height: 160px">
                      <v-col class="standard-text">
                        <span class="black-Text"> Language: </span>
                        JavaScript<br />
                        <span class="black-Text"> Framework: </span>
                        Vue<br />
                        <span class="black-Text">UI Library:</span> Vuetify
                        <br />
                        <span class="black-Text">Database:</span> SQL (MySql as
                        RDBMS)
                        <br />
                        <span class="black-Text">Server:</span>
                        Mars (JS as back-end language, authentication, storage)
                      </v-col>
                    </v-row>
                    <v-row justify="end" class="mb-0">
                      <v-col justify="end">
                        <div class="black--text captionCust pt-2 text-right">
                          More info...
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-flex>
            <!-- 7. PROJEKAT -->
            <v-flex xs12 md6 lg5>
              <v-hover class="mt-7" v-slot:default="{ hover }" style="min-height: 590px; height: auto">
                <v-card @click="$router.push('/bakery')" class="mx-auto;" style="cursor: pointer"
                  color="grey lighten-3"><v-img :aspect-ratio="16 / 9" src="../assets/projects/Bakery/slika-1.png">
                    <v-expand-transition>
                      <div v-if="hover"
                        class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal headline white--text text-center"
                        style="height: 100%">
                        Click for more info...
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pt-3" style="position: relative">
                    <div class="standard-text black--text subheading mb-2" style="height: 130px">
                      A desktop application for a bakery that serves for easier
                      money management. It can be easily adapted to another
                      business.
                    </div>
                    <br />

                    <h3 class="headlineCust orange--text mb-2">Bakery</h3>
                    <v-row style="height: 160px">
                      <v-col class="standard-text">
                        <span class="black-Text"> Language: </span>
                        Java<br />
                        <span class="black-Text">GUI widget:</span> Swing
                        <br />
                        <span class="black-Text">Database:</span> SQL (SQLite as
                        RDBMS) <br />
                      </v-col>
                    </v-row>
                    <v-row justify="end" class="mb-0">
                      <v-col justify="end">
                        <div class="black--text captionCust pt-2 text-right">
                          More info...
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-flex>

            <!-- 8. PROJEKAT -->
            <v-flex xs12 md6 lg5>
              <v-hover class="mt-7" v-slot:default="{ hover }" style="min-height: 590px; height: auto">
                <v-card @click="$router.push('/myRecipes')" class="mx-auto;" style="cursor: pointer"
                  color="grey lighten-3"><v-img :aspect-ratio="16 / 9" src="../assets/projects/MyRecipes/slika-1.png">
                    <v-expand-transition>
                      <div v-if="hover"
                        class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal headline white--text text-center"
                        style="height: 100%">
                        Click for more info...
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="pt-3" style="position: relative">
                    <div class="standard-text black--text subheading mb-2" style="height: 130px">
                      Desktop application for kitchen recipes. It is easy to add
                      new recipes as well as sorting by various criteria. Easy
                      way to find all recipes in one place.
                    </div>
                    <br />
                    <h3 class="headlineCust orange--text mb-2">My recipes</h3>
                    <v-row style="height: 160px">
                      <v-col class="standard-text">
                        <span class="black-Text"> Language: </span>
                        Java<br />
                        <span class="black-Text">GUI widget:</span> Swing
                        <br />
                        <span class="black-Text">Database:</span> SQL (SQLite as
                        RDBMS) <br /><br />
                      </v-col>
                    </v-row>
                    <v-row justify="end" class="mb-0">
                      <v-col justify="end">
                        <div class="black--text captionCust pt-2 text-right">
                          More info...
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-flex>

            <!-- 9. EMPTY PROJEKAT because of flex and justify-content:space-around -->
            <v-flex xs12 md6 lg5> </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    computed: {
      fontSize() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
          case "sm":
            return { "font-size": "1.35em !important" };
          default:
            return { "font-size": "1.4em" };
        }
      },
    },
  };
</script>

<style></style>
